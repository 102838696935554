<template>
  <div class="container" style="max-width: 500px">
    <div class="card">
      <div class="card-content">
        <img
          class="avatar"
          :src="bot.avatar"
          style="width: 50px; height: 50px; border-radius: 200px; object-fit: cover"
        />

        <div class="media">
          <div class="media-content">
            <div>
              <p class="title is-4">Upgrade {{ bot.handle }}</p>
              <p class="subtitle is-6">
                <strong>Pro plan</strong> for bot studio {{ subscription.subtitle }} - {{ timeframe }}
              </p>
            </div>
          </div>
        </div>

        <div class="content">
          <hr />

          <p><strong>Due today: </strong> ${{ subscription.price }}</p>

          <p><strong>Next payment: </strong> {{ renewalDate }}</p>

          <hr />
          <p v-if="balance">
            <strong>Wallet Balance: </strong>

            <span :class="{ 'has-text-danger': !sufficientFunds }"> ${{ balance }} </span>
          </p>
          <!--  
          <p><strong>Balance after Transaction: </strong> ${{ subscription.balanceAfterTransaction }}</p>
            <time :datetime="renewalDate">{{ renewalDate }}</time>
-->

          <div v-if="sufficientFunds">
            <b-button type="is-success" @click="confirmSubscription" class="is-fullwidth"
              >Pay with wallet balance</b-button
            >
            <br />

            Please note that some limits still applies to prevent abuse. You can cancel any time before the renewal
            date.
          </div>
          <div v-else class="has-text-danger">
            <b-button type="is-dark" @click="confirmSubscription" class="is-fullwidth">Add funds to Wallet</b-button>
            <br />
            Your OnlyBots wallet is a safe and simple way to support AI creators, subscribe to premium bots and run your
            own AI services.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const mockSubInfo = {
  price: 10.99,
  // balance: 50.0,
  // balanceAfterTransaction: 39.01,
};

export default {
  data() {
    return {
      bot: {},
      subscription: {
        price: "",
        balance: "",
        balanceAfterTransaction: "",
      },
      renewalDate: "",
    };
  },
  created() {
    this.getSubscriptionInfo();
  },
  computed: {
    botId() {
      return this.$route.query.bot;
    },
    type() {
      return this.$route.query.type;
    },
    timeframe() {
      return this.$route.query.yearly ? "yearly" : "monthly";
    },
    balance() {
      return this.$route.query.balance || this.$route.query.b || 50;
    },
    balanceAfterTransaction() {
      var price = this.subscription.price;
      var balance = this.balance;
      var balanceAfterTransaction = balance - price;
      return balanceAfterTransaction;
    },
    sufficientFunds() {
      return this.balanceAfterTransaction >= 0;
    },
    // subscription() {
    //   return this.subscription;
    // },
  },
  methods: {
    getSubscriptionInfo() {
      // comment out when using real API
      this.subscription = mockSubInfo;
      this.renewalDate = this.getRenewalDate();

      //retrive bot id from url
      //get subscription info from API

      window.API.botProfile(this.botId)
        .then((response) => {
          this.bot = response;
        })
        .catch((error) => console.error(error));

      window.API.getSubInfos(this.botId).then((response) => {
        this.subscription.price = response.price;
        this.subscription.balance = response.balance;
        this.subscription.balanceAfterTransaction = response.balanceAfterTransaction;
        this.renewalDate = this.getRenewalDate();
      });

      /*
            const urlParams = new URLSearchParams(window.location.search);
            const bot = urlParams.get('bot');
            const type = urlParams.get('type');
            window.API.getSubInfos(bot, type)
                .then(response => {
                    this.subscription.price = response.price;
                    this.subscription.balance = response.balance;
                    this.subscription.balanceAfterTransaction = response.balanceAfterTransaction;
                    this.renewalDate = this.getRenewalDate();
                })
                .catch(error => console.error(error));
            */
    },
    getRenewalDate() {
      let currentDate = new Date();
      if (this.timeframe === "yearly") currentDate.setFullYear(currentDate.getFullYear() + 1);
      else currentDate.setMonth(currentDate.getMonth() + 1);
      return currentDate.toISOString().split("T")[0];
    },
    confirmSubscription() {
      // Add logic to handle confirmation of the subscription
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
}
</style>
